@import 'variables';

@mixin mobile {

  // @media (max-width: #{$mobile-width - 1px}) {
  //   @content;
  // }
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}


@mixin large {
  @media (min-width: #{$desktop-large-width}) {
    @content;
  }
}

@mixin larger {
  @media (min-width: #{$desktop-larger-width}) {
    @content;
  }
}

@mixin mobile-and-tablet {
  @include mobile {
    @content;
  }

  @include tablet {
    @content;
  }
}

@mixin tablet-and-desktop {
  @include tablet {
    @content;
  }

  @include desktop {
    @content;
  }
}