.InitialsBadge {
  position: relative;
  z-index: 0;
  padding: 0 0.9rem;
  font-size: 1.25rem;

  &-wrapper {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    line-height: inherit;
    white-space: nowrap;
  }

  &-after {
    font-size: 0.75rem;
    position: absolute;
    font-weight: bold;
    background-color: $black;
    color: $white;
    height: 1.75rem;
    line-height: 1.75rem;
    text-align: center;
    width: 1.75rem;
    z-index: -1;
  }
}
