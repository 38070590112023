@import '../../../sass/mixins';
@import '../../../sass/variables';

.profile-page {
  @include desktop {
    padding: 0 7vw;
  }
}

.page-title {
  color: #32495e;
  font-weight: 500;
  font-size: 25px;
}

.my-profile-container {
  background-color: #fff;
  padding: 40px 15px;

  .my-profile-inner {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

    .user-upload-wrapper {
      width: 100%;
      float: left;

      .edit-btn {
        height: 42px;
        background-color: #edf1f4;
        border-radius: 30px;
        min-width: 110px;
        color: #808f9b;
        border: 1px solid #e0e0e0;
        font-weight: 500;
        margin: 0px 6px;
      }

      .file-upload {
        background-color: #5fa01b;
        border-radius: 30px;
        min-width: 110px;
        color: #fff;
        border: 1px solid #5fa01b;
        font-weight: 500;
        margin: 0px 6px;
        cursor: pointer;

        input {
          display: none;
        }
      }
    }

    .myprofile-frm-right {
      .form-group {
        margin-bottom: 20px;
      }

      input,
      select {
        border: none;
        color: #42425d;
        border-bottom: 1px solid #eaeaf1;
        border-radius: 0px;
        padding-left: 0px;
        font-weight: 400;
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;

        &:active,
        &:focus {
          box-shadow: none;
        }

        &:focus {
          border-color: #5fa01b;
        }
      }

      .time-input {
        &>div:first-of-type {
          margin-bottom: 20px;
        }

        .radio-btn-wrapper {
          position: absolute;
          top: 6px;
          right: 12px;
          margin: 0;

          input[type='radio'] {
            display: none;
          }

          label {
            margin: 0;
            cursor: pointer;

            &:not(:last-of-type) {
              margin-right: 4px;
            }

            span {
              background-color: #e9e9f0;
              border-radius: 100%;
              font-size: 10px;
              color: #7f8e9a;
              width: 26px;
              height: 26px;
              float: left;
              display: -webkit-flex;
              -webkit-justify-content: center;
              -webkit-align-items: center;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            input:checked+span {
              background-color: #5fa01b;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .actions {
    display: flex;

    button {
      width: 30%;
    }

    .actions-delete {
      display: flex;
      flex-grow: 1;
      justify-content: left;
      width: 100%;
    }

    .actions-update {
      display: flex;
      flex-grow: 2;
      justify-content: right;
      width: 100%;
      gap: 20px;
    }

    @include mobile {
      flex-direction: column-reverse;

      .actions-delete {
        justify-content: center;
        width: 100%;
      }

      .actions-update {
        margin-bottom: 20px;
        justify-content: center;
      }

      button {
        width: 100%;
      }
    }
  }

}