@import '../../../sass/variables';

.Checkbox {
  position: relative;
  display: flex;
  align-items: center;

  min-height: 26px;
  align-items: center;

  &-custom {
    opacity: 0;
    position: absolute;
  }

  &-custom,
  &-customLabel {
    z-index: 10;
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
    // position: absolute;
    // left: 0;
    // top: -5px;
    background: transparent;

    color: $gray-600;
    font-size: 14px;
    font-weight: $font_weight_400;
    margin-left: 15px;
    display: flex;
    flex-direction: row;
  }

  &-customBorder:before {
    cursor: pointer;
    content: '';
    background: $gray-100;
    border-radius: 4px;
    border: 1px solid $gray-800;
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    padding: 2px;
    margin: 3px 5px;
    text-align: center;
  }

  &-custom:checked+&-customLabel:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 11px;
    border: solid $primary;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    background: transparent;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    border-radius: 0;
    margin: 1px 15px 4px 5px;
    position: absolute;
    left: 6px;
    top: 9px;
  }
}