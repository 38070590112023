#root {
  display: flex;

  >.container-fluid,
  >.container {
    // height: 100vh;
    overflow-y: auto;
  }

  outline: none;
}

main {
  &.container-fluid {
    padding: 0;
  }
}

* {
  outline: none !important;
}

button {
  border: none;

  &:disabled,
  &[disabled] {
    cursor: default !important;
    background: #e0e0e0 !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.pointer {
  cursor: pointer;
}

// @media (min-width: 100rem) {
//   html {
//     font-size: 1.2rem;
//   }
// }

// Hide spinner in number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.text-underline {
  &-medium {
    text-decoration: underline;
    text-decoration-color: $primary;
    text-decoration-thickness: 4px;
  }

  &-bold {
    text-decoration: underline;
    text-decoration-color: $primary;
    text-decoration-thickness: 4px;
  }
}

#main-body {
  margin-top: 70px;
}

.a-tag {
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}