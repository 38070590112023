.SideNav {
  min-height: 100vh;
  min-width: 16.25rem;
  transition: margin 240ms ease-out;
  background-color: $sidebar-bg;

  .navbar {
    min-height: 4.375rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .list-group {
    background-color: transparent;
    margin: 0.75rem 0;
    width: 100%;

    .list-group-item {
      background-color: transparent;
      border-color: $sidebar-bg;
      border-left: 5px solid $sidebar-bg;
      color: $sidebar-link-color;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      margin: 0.25rem 0;
      text-decoration: none;
      padding: 1.375rem 1.5625rem;
      display: flex;
      align-items: center;

      svg {
        fill: $sidebar-link-color;
        vertical-align: middle;
        margin-right: 0.75rem;
      }

      &:focus {
        outline: 1px dashed $primary;
      }

      &.active,
      &:hover {
        color: $white;
        background: $sidebar-link-active-bg;
        border-left: 5px solid $primary;

        svg {
          fill: $primary;
        }
      }
    }
  }
}
