@import '../../../sass/variables';
@import '../../../sass/mixins';

.OrderDetail {
  $detail-color: #7b8a96;

  &.container-fluid {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
    padding-bottom: 3.125rem;

    @include mobile {
      padding: 1rem;
    }
  }

  .heading {
    display: flex;
    align-items: center;
    padding-bottom: 1.125rem;
    padding-top: 1.45rem;

    .back-btn {
      margin-right: 1rem;
      cursor: pointer;
    }

    h4 {
      margin: 0;
    }

    .search {
      position: relative;
      margin-left: auto;
      width: auto;

      input {
        background: transparent;
        border-radius: 1.875rem;
        border: 1px solid $detail-color;
        color: $detail-color;
        font-size: 0.8125rem;
        font-weight: 300;
        height: 2.75rem;
        padding: 0 0.9375rem;
        padding-left: 3.125rem;
        width: 300px;

        &:focus {
          outline: 0;
        }
      }

      .input-group-prepend {
        position: absolute;
        left: 1.25rem;
        top: 0.625rem;
      }
    }

    .order-actions {
      position: relative;
      margin-left: auto;
      width: auto;
      display: flex;
      gap: 20px;

      input {
        background: transparent;
        border-radius: 1.875rem;
        border: 1px solid $detail-color;
        color: $detail-color;
        font-size: 0.8125rem;
        font-weight: 300;
        height: 2.75rem;
        padding: 0 0.9375rem;
        padding-left: 3.125rem;
        width: 300px;

        &:focus {
          outline: 0;
        }
      }

      .input-group-prepend {
        position: absolute;
        left: 1.25rem;
        top: 0.625rem;
      }

      button {
        height: 40px;
        font-size: 13px;
        padding: 0 20px;
      }
    }
  }

  .invoice-detail {
    background: $white;
    background: $white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.14);
    margin-bottom: 22px;
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    padding: 1.25rem;

    &:last-child {
      margin-bottom: 0;

      .detail-col {
        p {
          margin-bottom: 0.3125rem;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .detail {
    &-col {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding: 1.25rem 1.25rem;

      @include mobile {
        flex: 0 0 100%;
        max-width: 100%;
      }

      p {
        color: #4d4f5c;
        font-size: 0.8125rem;
        font-weight: bold;
        margin: 0;
        padding: 0;

        span {
          font-weight: 400;
        }
      }
    }

    &-row {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(112, 112, 112, 0.16);

      &:last-child {
        border: 0;
      }
    }
  }

  .status-badge {
    background: #feb42c;
    color: $white;
    font-size: 0.75rem;
    padding: 0.1875rem 0.625rem;
    border-radius: 1.25rem;
    margin-left: 0.125rem;
    text-transform: capitalize;
  }

  .table-container {
    overflow-x: scroll;

    .table.products-list {
      margin: 0;
      table-layout: fixed;

      @include mobile-and-tablet {
        table-layout: auto;
      }

      thead {
        th {
          border: 0;
          vertical-align: middle;
          background: #edf1f4;
          color: $detail-color;
          text-transform: uppercase;
          font-family: Source Sans Pro;
          font-size: 0.8125rem;
          font-weight: bold;
          padding: 0.875rem 0.875rem;
        }
      }

      tbody {
        td {
          background: $white;
          border: 0;
          background: transparent;
          border: 0;
          color: #4d4f5c;
          font-weight: 400;
          font-size: 0.875rem;
          padding: 1.25rem 0.875rem;

          &.input {
            &-qty {
              &>div {
                width: unset;
              }

              input {
                width: 50px;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: auto;
                  margin: 0;
                }

                &[type='number'] {
                  -moz-appearance: inherit
                }
              }
            }
          }

          h2 {
            color: #4d4f5c;
            font-weight: 400;
            font-size: 0.875rem;
            margin: 0;
            padding: 0;
            margin-bottom: 0.125rem;
          }

          p {
            font-size: 0.6875rem;
            font-weight: 400;
            color: $detail-color;
            margin: 0;
            padding: 0;
          }
        }

        .item-actions {
          display: flex;
          justify-content: space-between;

          .item-action {
            cursor: pointer;

            &.green {
              color: green
            }

            &.red {
              color: red
            }

            &.grey {
              color: $gray-600
            }
          }
        }

        tr:nth-child(2n + 2) {
          background: #f7f9fa;
        }
      }

      .dropdown {
        button {
          width: 140px;
          cursor: text;

          &.PENDING,
          &.NEW,
          &.PREPARING,
          &.PROCESSING {
            background: $yellow;
          }

          &.OUT_OF_STOCK,
          &.FAILED,
          &.CANCELLED,
          &.REMOVED {
            background: $red;
          }
        }
      }

      tbody {}

      svg {
        margin-left: 0.3125rem;
      }

      .btn {
        color: $white;
        background: $primary;
        border: 0;
        border-radius: 1.875rem;
        font-size: 0.75rem;
        padding: 0.3125rem 1.25rem;
        color: $white;
        background: $primary;
        border: 0;
        border-radius: 1.875rem;
        font-size: 0.75rem;
        padding: 0.3125rem 0.9375rem 0.3125rem 0.9375rem;

        &:hover {
          background: $primary;
          border: 0;
        }

        &:after {
          display: none;
        }
      }
    }

    .table.products-list {}
  }


  .total-amounts {
    margin-top: 1.875rem;
    text-align: right;

    h4 {
      color: $secondary;
      font-size: 1.75rem;
      font-weight: bold;
      margin-top: 22px;
      margin: 0;
      padding: 0;
    }

    p {
      color: $secondary;
      font-size: 0.9375rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      margin: 0;
      padding: 0;
    }
  }
}