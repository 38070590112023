@import '../../../../sass/mixins';
@import '../../../../sass/variables';

#Header {
  $profile-name-color: #585858;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);

  .navbar {
    min-height: 4.375rem;

    .nav-item {

      .unauth-btn {
        align-items: center;
        display: flex;
        border-radius: 100px;
        justify-content: center;
        height: 40px;
        border-radius: 5px;
        padding: 10px 27px;
      }

      .nav-login {
        @extend .unauth-btn;
        color: white;
        background-color: $primary;
      }

      .nav-signup {
        @extend .unauth-btn;
        color: $primary;
        background-color: white;
        border: 1px solid $primary;
      }
    }

    .dropdown {
      .nav-link {
        display: flex;
        flex-flow: row;
        font-size: 0.9rem;
      }

      .InitialsBadge {
        &-wrapper {
          padding: 0;
          margin: -0.25rem 0 -0.35rem 0;
        }
      }
    }

    .notification-item {
      padding-left: 1.5rem;
      margin-left: 1rem;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        border-left: 1px solid #ebebf2;
      }

      @include mobile {
        border-left: none;
        padding-left: 0;
        margin-left: 0;
      }
    }

    .profile-name {
      font-size: 0.8125rem;
      color: $profile-name-color;
      font-weight: 400;
      margin-right: 0.75rem;

      svg {
        margin-left: 0.625rem;
      }
    }
  }

  @media (max-width: 767px) {
    .navbar {
      padding: 0.925rem 2rem;

      // .navbar-toggler {
      //   position: absolute;
      //   right: 10px;
      // }
    }

    .navbar-expand-md {
      .navbar-collapse {
        .navbar-nav {
          // margin-bottom: 10px;
          // padding-top: 1rem;
          padding-top: 1rem;
          gap: 0.5rem;

          // .nav-item:not(:first-child) {
          //   margin-top: 10px;
          //   margin-bottom: 10px;
          // }
        }
      }
    }
  }
}