@import '../../node_modules/bootstrap/scss/functions'; // must import this in order to use bootstrap variables
@import '../../node_modules/bootstrap/scss/variables';

// Colors
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #373a3c;
$gray-900: #212529;
$black: #000;

$blue: #2780e3;
$indigo: #6610f2;
$purple: #613d7c;
$pink: #e83e8c;
$red: #ff0039;
$orange: #ff7518;
$yellow: #feb42c;
$green: #3fb618;
$teal: #20c997;
$cyan: #9954bb;

$primary: #47B853;
$primary-hover: #6fab31;
$primary-light: #ebfbee;
$secondary: #32495e;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

// Theme Colors
$theme-colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-700,
  'black': $black,
);

$font_weight_700: 700;
$font_weight_600: 600;
$font_weight_400: 400;
$font_weight_300: 300;

$font_size_high: 36px;
$font_size_medium: 18px;
$font_size_low: 15px;
$font_face_error: 12px;

// Options
// $body-bg: #edf1f4;
$body-color: $gray-800;
$enable-rounded: false;
$font-family-sans-serif: 'Poppins',
  sans-serif;
$modal-fade-transform: scale(0.8);

// Custom variables
$sidebar-bg: $secondary;
$sidebar-link-color: #768c9a;
$sidebar-link-active-color: $white;
$sidebar-link-active-bg: #233143;

// screen sizes
$mobile-width: map-get($grid-breakpoints, "sm");
$tablet-width: map-get($grid-breakpoints, "md");
$desktop-width: map-get($grid-breakpoints, "lg");
$desktop-large-width: map-get($grid-breakpoints, "xl");
$desktop-larger-width: 1400px;