// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Animate.css
// @import '~animate.css/animate.min.css';

// Sass customizations
@import '../components/Navigation/Header/Unauthenticated/Header';
@import '../components/Navigation/Sidenav/Sidenav';
@import '../components/Navigation/Header/Unauthenticated/InitialsBadge';

// custom override
@import 'custom';
