@import '../../../sass/mixins';

.checkout {
  margin-top: 70px;
}

.personal-information-page {
  padding: 48px 0;

  .payment-method {
    border: 1px solid #d8d8d8;
    border-radius: 20px;
    width: 130px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
  }

  .personal-information-form {
    width: 100%;
  }
}

#checkout-form {
  display: flex;
  flex: 2;

  .form-group-left {
    flex: 2;

    h3 {
      margin-top: 0;
    }
  }

  .form-group-right {
    flex: 1;
    margin-left: 50px;

    .form-group {
      display: flex;
      flex-direction: column-reverse;
    }

    @include mobile-and-tablet {
      margin-left: 0;

      .form-group {
        flex-direction: column;
      }
    }
  }

  @include desktop {
    .form-group-left {
      width: 68%;
      flex: unset;
    }

    .form-group-right {
      .form-group {
        position: fixed;
        right: 5%;
        width: 25%;
      }
    }
  }

  .form-group {
    h3 {
      font-size: 20px;
      font-weight: normal;
      margin: 20px 0;
    }

    .form-row {
      display: flex;
      flex-wrap: unset;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 28px;
      }

      .input-group {
        input {
          &:focus {
            box-shadow: 0 1px 3px 0 #cfd7df;
          }
        }
      }
    }

    .StripeElement {
      padding: 15px 31px;
      font-size: 13px;
      width: 100%;
      color: #32325d;
      background-color: white;
      border: 1px solid #d8d8d8;
      border-radius: 5px;
    }

    .StripeElement--focus {
      box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
      border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
      background-color: #fefde5 !important;
    }
  }

  .checkout-summary {
    flex: 1;

    .summary {
      background-color: #f7f7f7;
      padding: 12px 21px 25px 21px;
      margin-bottom: 20px;
      // height: 100%;
      border-radius: 15px;
      flex: 1;

      h2 {
        font-size: 25px;
        font-weight: 600;
      }
    }
  }

  @include mobile-and-tablet {
    flex-direction: column;

    .form-group {
      .form-row {
        display: unset;

        .input-group {
          &:not(:last-child) {
            margin-bottom: 28px;
          }
        }
      }
    }

    .checkout-summary {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.checkout-modal {
  .modal-container {
    width: 40%;

    @include mobile {
      width: 90%;
    }
  }
}