@import '../../../sass/mixins';

.home-page {
  margin-top: 70px;

  @include desktop {
    // margin-left: 7%;
    // width: 86%;
  }

  @include mobile {
    // padding: 0 20px;
  }

  .home-page-signup {
    margin-bottom: 110px;
  }

  .home-page-intro {
    .intro-item {
      .intro-img {
        img {
          width: 50%;
          height: 50%;
        }
      }
    }
  }
}