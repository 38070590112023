@import '../../../sass/mixins';

#footer {
  padding: 20px 2vw 20px;
  bottom: 0;
  background-color: #fff;
  width: 100%;

  .footer-content {
    display: flex;
    justify-content: space-between;
    // color: #fff;

    a {
      text-decoration: none;
    }

    @include mobile {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: center;
    }

    &-column {
      display: flex;
      // flex-direction: column;

      // @include mobile {
      //   &:not(:last-child) {
      //     margin-bottom: 20px;
      //   }
      // }

      &-row {
        &:not(:last-child) {
          // margin-bottom: 20px;
          margin-right: 20px;
        }
      }

      &-links {
        @include mobile {
          padding: 10px 0;
        }
      }
    }
  }

  .footer-copyright {
    font-size: 12px;
    color: #AEAEAE;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 51px;
    display: flex;
    flex-direction: column;
  }

  .platform-availability {
    div {
      margin-bottom: 24px;
    }
  }

  .social {
    margin-top: 17px;
    display: flex;
    flex-wrap: wrap;

    div {
      margin-bottom: 22px;
      width: 33%;
    }
  }
}

.tac-modal {
  .modal-container {
    width: 70%;
    height: 100%;
    padding: 20px 40px;

    .tac {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      padding: 1rem 3rem 0;

      @include mobile {
        padding: 20px 0;
      }

      .tac-section {
        &:last-child {
          padding-bottom: 20px;
        }
      }
    }
  }
}